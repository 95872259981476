import ReactGA from 'react-ga';

export const gaInit = () => {
    console.log('Initializing Google Analytics services');
    ReactGA.initialize('UA-51870114-7');
};

export const gaEvent = (category: string, action: string, label: string, value: number = null) => {
    ReactGA.event({
        category,
        action,
        label,
        value
    });
};

export const gaVisitPage = url => {
    ReactGA.pageview(`${url}`);
};
