import React, {
    createContext,
    useContext,
    useState
} from 'react';
import { useCookies } from 'react-cookie';

const AuthContext = createContext({
    isAuthenticated: false,
    setLogin: (_token: string, _options: any) => {},
    setLogout: () => {}
});

export const AuthProvider = ({ children }) => {
    const [
        cookies,
        setCookie,
        removeCookie
    ] = useCookies([ 'access_token' ]);
    const [ isAuthenticated, setAuthenticated ] = useState(!!cookies.access_token);

    const setLogin = (token: string, options: any) => {
        setCookie('access_token', token, options);
        setAuthenticated(true);
    };
    const setLogout = () => {
        removeCookie('access_token');
        setAuthenticated(false);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setLogin,
                setLogout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
